<template>
  <div
    class="register-container"
    :style="{
      backgroundImage: 'url(' + bg3 + ')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
    }"
  >
    <div class="login-form-box">
      <div class="register-header">
        <h2 class="title">
          {{ doText }}
        </h2>
        <el-button type="text" @click="toLogin">已有账号去登录 >></el-button>
      </div>
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
        auto-complete="on"
        label-position="left"
      >
        <el-form-item prop="phone" class="username">
          <span class="svg-container">
            <i class="el-icon-phone"></i>
          </span>
          <el-input
            class="phone"
            ref="username"
            v-model="loginForm.phone"
            placeholder="请输入手机号"
            name="username"
            type="text"
            tabindex="1"
            clearable
            :maxlength="11"
          />
        </el-form-item>

        <el-form-item prop="code" class="password">
          <span class="svg-container">
            <i class="el-icon-s-grid"></i>
          </span>
          <div class="code">
            <el-button
              :disabled="isWait"
              type="primary"
              size="mini"
              @click="getCode"
              plain
              >{{ text }}</el-button
            >
          </div>
          <el-input
            ref="code"
            v-model="loginForm.code"
            placeholder="验证码"
            name="code"
            tabindex="2"
          />
        </el-form-item>

        <el-form-item prop="password" class="password">
          <span class="svg-container">
            <i class="el-icon-lock"></i>
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="请输入8-16位密码"
            name="password"
            tabindex="2"
            @input="changeValue"
            @keyup.enter.native="handleLogin"
            :minlength="8"
            :maxlength="16"
          />
          <span class="show-pwd" @click="showPwd">
            <i v-if="showPassword" class="iconfont">&#xe606;</i>
            <i v-else class="iconfont">&#xe64c;</i>
          </span>
        </el-form-item>

        <el-form-item prop="password2" class="password">
          <span class="svg-container">
            <i class="el-icon-lock"></i>
          </span>
          <el-input
            :key="passwordType2"
            ref="password2"
            v-model="loginForm.password2"
            :type="passwordType2"
            placeholder="请再次确认密码"
            name="password"
            tabindex="2"
            @keyup.enter.native="handleLogin2"
            :minlength="8"
            :maxlength="16"
          />
          <span class="show-pwd" @click="showPwd2">
            <i v-if="showPassword2" class="iconfont">&#xe606;</i>
            <i v-else class="iconfont">&#xe64c;</i>
          </span>
        </el-form-item>

        <el-button
          :loading="loading"
          type="primary"
          class="login-btn"
          @click.native.prevent="handleLogin"
          >立即注册</el-button
        >
      </el-form>
      <div class="bottom-text">
        <el-checkbox v-model="radio"></el-checkbox>
        同意接受
        <el-button type="text">《会员服务协议》</el-button>
        <el-button type="text">《交易服务协议》</el-button>
        <el-button type="text">《隐私保护声明》</el-button>
      </div>
    </div>
  </div>
</template>

<script>
var passwordInit = "";
export default {
  name: "Login",
  props: {
    doText: {
      type: String,
      default: "用户注册",
    },
  },
  data() {
    const validateUsername = (rule, value, callback) => {
      const reg = /^1[3446789]\d{9}$/;
      if (value === "" || !reg.test(value)) {
        return callback(new Error("请输入正确格式的手机号码 "));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (value.length !== 6) {
        callback(new Error("请输入六位验证码"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 8 || value.length > 16) {
        callback(new Error("请输入8-16位登录密码"));
      } else {
        callback();
      }
    };
    const validatePassword2 = (rule, value, callback) => {
      if (value.length < 8 || value.length > 16) {
        callback(new Error("请输入8-16位登录密码"));
      } else if (value !== passwordInit) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      radio1: "个人",
      bg3: require("@/assets/images/login-bk.png"),
      loginForm: {
        username: "",
        phone: "",
        password: "",
        password2: "",
        code: "",
      },
      showPassword: true,
      showPassword2: true,
      value: "+86",
      options: [
        {
          value: "+86",
        },
        {
          value: "+086",
        },
        {
          value: "+0086",
        },
      ],
      radio: false,
      loginRules: {
        username: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            trigger: "blur",
            validator: validateUsername,
          },
        ],
        code: [
          {
            required: true,
            trigger: "blur",
            validator: validateCode,
          },
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            validator: validatePassword,
          },
        ],
        password2: [
          {
            required: true,
            trigger: "blur",
            validator: validatePassword2,
          },
        ],
      },
      loading: false,
      passwordType: "password",
      passwordType2: "password",
      redirect: undefined,
      timeNum: 60,
      timer: null,
      fromPath: "",
      tipArr: [
        {
          tab: "个人",
          tips: "适用于发明人、专家、技术中介个人、投资创业者、个体户、个体工商业者等。",
        },
        {
          tab: "企业",
          tips: "适用于个人独资企业、企业法人、企业非法人、企业分支机构等能提供营业执照的用户主体。",
        },
        {
          tab: "院校",
          tips: "适用于全日制大学、独立设置的学院、高等专科学校、职业技术学院等",
        },
        {
          tab: "研究机构",
          tips: "适用于实验室、研究院、新型研发机构等",
        },
        {
          tab: "政府",
          tips: "适用于行政机构及事业单位",
        },
        {
          tab: "园区",
          tips: "适用于科技园、产业园、工业园、孵化器、育成中心、众创空间等",
        },
        {
          tab: "其它组织",
          tips: "适用于产业、科技、创新相关的各类协会、团体。",
        },
      ],
    };
  },
  computed: {
    text() {
      return this.timeNum === 60 ? "获取" : `${this.timeNum} 后重新获取`;
    },
    isWait() {
      return this.timeNum === 60 ? false : true;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromPath = from.path;
    });
  },
  created() {},
  beforeDestroy() {
    clearInterval(this.timer);
  },

  methods: {
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (this.radio) {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
              this.$message({
                message: "注册成功，即将自动前往登录页面",
                type: "success",
              });
              setTimeout(() => {
                this.$router.push("/login");
              }, 500);
            }, 1000);
          } else {
            this.$message.warning("请勾选协议");
            return false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    changeValue(val) {
      passwordInit = val;
    },
    showPwd() {
      this.showPassword = !this.showPassword;
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    showPwd2() {
      this.showPassword2 = !this.showPassword2;
      if (this.passwordType2 === "password") {
        this.passwordType2 = "";
      } else {
        this.passwordType2 = "password";
      }
      this.$nextTick(() => {
        this.$refs.password2.focus();
      });
    },
    getCode() {
      let that = this;
      this.timer = setInterval(() => {
        that.setNum();
      }, 1000);
    },
    setNum() {
      if (this.timeNum === 0) {
        clearInterval(this.timer);
        this.timeNum = 60;
      } else {
        this.timeNum--;
      }
    },
    toLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="stylus" scoped>
.register-container
    min-height calc(100vh - 60px)
    display flex
    flex-direction row-reverse
    .svg-container
        position absolute
        padding: 6px 5px 6px 15px
        color: #666666
        vertical-align: middle
        width: 30px
        z-index: 2
        i
            font-size: 20px
    .register-header
        display: flex
        justify-content space-between
        .title
            font-size 18px
            color #030D41
        .tip
            font-size 12px
            padding-bottom 60px
            border-bottom 1px solid #D0D0D2
    .show-pwd
        position: absolute
        right: 10px
        top: 7px
        font-size: 16px
        color: #666666
        cursor: pointer
        user-select: none
</style>

<style lang="stylus">
.register-container
    color #666
    .bottom-text
        font-size 12px
        line-height: 24px
        .el-button
            padding: 0
    .el-form-item__error
        z-index 999
    .login-form-box
        width 440PX
        background #fff
        padding 30px
        margin 100px 200px 100px 0
        height fit-content
        .el-form-item
            margin-bottom 10px
            border-bottom 1px solid #D0D0D2
            &:first-child
                padding-bottom: 10px
            .el-input__inner
                padding-left 50px
            .code
                align-items center
                position absolute
                display flex
                height 46px
                right 0
                top 0
                z-index 2
        .login-btn
            width 100%
            margin-top 20px
            margin-bottom: 20px
    input
        border none
        outline none
        line-height 46px
        height 46px
    .el-radio-button:first-child:last-child
        .el-radio-button__inner
            border-radius: 0
            padding: 6px 10.5px
</style>
